<template>
  <b-container fluid>
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img src="../assets_gide/img/logo/GideWhiteNRed.jpeg" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="eventDetail.event_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="eventDetail.event_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="8" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="eventDetail.event_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-0">
            Email OTP Verification
          </h3>
          <ValidationObserver ref="userActivationForm">
            <form class="mt-4">
              <div class="form-group">
                <ValidationProvider name="Activation code" rules="required" v-slot="{ errors }">
                  <input v-model="userActivationCode" type="text" class="form-control mb-0" id="exampleInputPassword1" placeholder="Enter OTP Here">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="d-inline-block w-100">
                <button class="btn btn-primary" type="button" v-if="resendEmailBtn" @click="userActivationEmail()">
                  Resend Email
                </button>
                <button type="button" @click="userActivate" class="btn btn-primary float-right">
                  Validate
                </button>
              </div>
              <span v-if="!resendEmailBtn" @click="userActivationEmail()">
                otp sent to {{userEmail}} <br/>You can request for otp after 10 secs
              </span>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">Go back to <router-link :to="{ path: 'login'}">Sign In</router-link></span>
              </div>
            </form>
          </ValidationObserver>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "Activate",
  components: {
    ValidationProvider
  },
  data: () => ({
    eventDetail: { event_img: "https://gide-assets-prod.s3.amazonaws.com/EVENT/EVEcff7a6d503ee443b86553f529683d4e3/8a48d740-2ee6-11ef-864f-03eb2ac38166.png", event_desc: "Join HSM Connect, a virtual Career Counseling & University Fair scheduled on July 20 & 21, where students and parents from different regions can connect & interact with 20+ universities from across the globe. Students & parents will get to learn about academic programs, scholarships, campus life, entry requirements, timelines & much more." },
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userId: null,
    userActivationCode: "",
    operatingsystem: window.__DEVICE_OS__,
    resendEmailBtn: false
  }),
  mounted () {
    this.showResendEmail()

    if (this.$route.query.code) {
      let code = JSON.parse(atob(this.$route.query.code))
      this.userEmail = code.user_email
      this.userId = code.user_id = 0 || code.user_id == "0" ? null : code.user_id
      this.userActivationCode = code.otp
    }
    else if (this.userData) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
    else {
      this.$router.push("/login")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * showResendEmail
     */
    showResendEmail () {
      setTimeout(() => {
        this.resendEmailBtn = true
      }, 10000)
    },
    /**
     * userActivationEmail
     */
    async userActivationEmail () {
      await User.userActivationEmail(this, this.userEmail)
      this.toastMsg = "Email Resent"
      this.showToast = true
      this.toastVariant = "success"
      this.resendEmailBtn = false
      this.showResendEmail()
    },
    /**
     * userActivate
     */
    async userActivate () {
      let valid = await this.$refs.userActivationForm.validate()
      if (!valid) {
        return
      }

      let userObj = {
        "user_email": this.userEmail,
        "user_id": this.userData && this.userData.user_id ? this.userData.user_id : this.userId,
        "activation_code": this.userActivationCode
      }
      let userActivateResp = await User.userActivate(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userActivateResp)
      if (userActivateResp.resp_status) {
        // If user not found than set basic profile details and redirect to registartion page
        // let userProfileData = {
        //   user_email: this.userEmail,
        //   is_profile_complete: false
        // }
        this.$store.dispatch("User/setUserProfileAction", userActivateResp.resp_data.data)

        setTimeout(() => {
          this.$router.push("/quick-profile")
        }, 1000)
      }
    }
  }
}
</script>
